const LANGUAGE_TEXTS = {
	QUESTIONNAIRE_TEXT: 'Cuestionario',
	RETRY_TEXT: 'Reintente',
	ENTRY_SUCCESS_TEXT: "Gracias por completar el cuestionario, le dejaremos pasar en breve.",
	ENTRY_FAILURE_TEXT: "Gracias por completar el cuestionario, lamentamos a informarle que no podemos atenderlo en persona en este momento, debido a Covid-19. Si aún desea que le atiendan, llame a la oficina al 800-799-5007",
	VISITOR_INFO_TEXT: 'Bienvenido, cuéntanos un poco sobre ti.',
	VISITOR_NAME_TEXT: '¿Cuál es su nombre?',
	VISITOR_PHONE_NUMBER_TEXT: 'Ingrese su número de teléfono',
	VISITOR_SELECT_OFFICE_LOCATION_TEXT: 'seleccione una ubicación',
	OFFICE_LOCATION_TEXT: 'Localización de la oficina',
	REQUIRED_TEXT: 'Requerida',
	VISITOR_REASON_TEXT: 'Razón de la visita',
	NEXT_TEXT: 'Proxima',
	GENERIC_REQUEST_ERROR_TEXT: 'No podemos procesar su solicitud en este momento. Por favor, inténtelo de nuevo más tarde',
	YES_TEXT: 'Sí',
	NO_TEXT: 'No',
	QUESTIONS_TEXT: [
		'¿Usted o algún miembro de su hogar ha experimentado algún síntoma de COVID-19, como fiebre, tos o dificultad para respirar?',
		'¿Ha estado usted o un miembro de su hogar fuera del país en los últimos 14-18 días?',
		'¿Ha estado usted o un miembro de su hogar en contacto cercano con alguien que haya sido examinado, diagnosticado o que un profesional médico le haya dicho que puede tener COVID-19?',
		'¿Ha estado usted o un miembro de su hogar en contacto cercano con alguien que haya viajado al extranjero o un área de alto riesgo en los últimos 14 a 18 días?',
	],
	INPUT_ERROR_TEXT: 'No podemos procesar su solicitud, verifique su entrada y inténtelo de nuevo!',
};

export default LANGUAGE_TEXTS;
