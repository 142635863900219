export const MSG_GENERIC_ERROR = 'We are unable to process your request at this time, please try again later.';
export const QUESTIONS_LIST = [
	'Have you or a member of your household been experiencing any symptoms of COVID-19, including fever, cough, or shortness of breath?',
	'Have you or a member of your household been out of the country within the last 14-18 days?',
	'Have you or a member of your household been in close contact with anyone who has been tested, diagnosed, or told by a medical professional they may have COVID-19?',
	'Have you or a member of your household been in close contact with anyone who has travelled overseas or to a high-risk area within the last 14-18 days?',
];
export const LANGUAGE_OPTIONS = {
	en: 'English',
	es: 'Espanol',
}
