<template>
      <div class="col col-12 col-lg-6 mx-auto pl-0 pr-0">
        <div class="card">
          <div class="card-header text-left">
            <h4 class="m-0">{{ appName }}</h4>
          </div>
          <template v-if="!hasSelectedLanguage">
            <div class="card-body mx-auto pt-5 card-language">
              <b-form-select
                id="language_id"
                v-model.trim="selectedLanguage"
                :options="languageOptions"
                @change="selectLanguage"
                placeholder="Language"
              />
            </div>
          </template>
          <template v-else>
            <template v-if="hasError">
                        <div class="card-body input-error page-error">
                          {{ formMessage }}

                          <div class="col col-12 text-center mt-5">
                            <button @click="retrySubmission" class="btn btn-secondary btn-lg">{{ systemLanguages.RETRY_TEXT }}</button>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="card-body" v-if="canProceed === true">
                          {{ systemLanguages.ENTRY_SUCCESS_TEXT }}
                        </div>
                        <div class="card-body" v-else-if="canProceed === false">
                          {{ systemLanguages.ENTRY_FAILURE_TEXT }}
                        </div>
                        <template v-else>
                            <b-form @submit="saveInfo" v-if="!step">
                            <div class="card-body text-left">
                              <div class="col col-12 p-0">
                                <div class="pb-4">
                                  {{ systemLanguages.VISITOR_INFO_TEXT }}
                                </div>
                                <div class="col col-12 p-0">
                                    <b-form-group
                                    id="name-group-1"
                                    label=""
                                    :class="{ 'required-input': true }"
                                    label-for="name"
                                  >
                                    <b-form-input
                                      id="name"
                                      autofocus
                                      v-model.trim="$v.form.name.$model"
                                      :class="{ 'has-error': $v.form.name.$error }"
                                      :disabled="isSubmitting"
                                      :placeholder="systemLanguages.VISITOR_NAME_TEXT"
                                      autocomplete="new-password"
                                    />
                                    <div
                                      v-if="$v.form.name.$dirty && $v.form.name.$error"
                                      class="input-error"
                                    >
                                      {{ getNameErrorMessage }}
                                    </div>
                                  </b-form-group>
                                  <div class="clearfix">&nbsp;</div>
                                    <b-form-group
                                    id="phone-group-1"
                                    label=""
                                    :class="{ 'required-input': true }"
                                    label-for="phone_number"
                                  >
                                    <b-form-input
                                      id="phone_number"
                                      v-model.trim="$v.form.phone_number.$model"
                                      :class="{ 'has-error': $v.form.phone_number.$error }"
                                      :disabled="isSubmitting"
                                      :formatter="acceptNumber"
                                      :placeholder="systemLanguages.VISITOR_PHONE_NUMBER_TEXT"
                                      autocomplete="new-password"
                                    />
                                    <div
                                      v-if="$v.form.phone_number.$dirty && $v.form.phone_number.$error"
                                      class="input-error"
                                    >
                                      {{ getPhoneErrorMessage }}
                                    </div>
                                  </b-form-group>
                                  <div class="clearfix">&nbsp;</div>
                                    <b-form-group
                                    label=""
                                    :class="{ 'required-input': true }"
                                    label-for="location_id"
                                  >
                                    <b-form-select
                                      id="location_id"
                                      v-model.trim="$v.form.location_id.$model"
                                      :options="locationOptions"
                                      :class="{ 'has-error': $v.form.location_id.$error }"
                                      :disabled="isSubmitting"
                                      :placeholder="systemLanguages.VISITOR_LOCATION_TEXT"
                                      autocomplete="new-password"
                                    />
                                    <div
                                      v-if="$v.form.location_id.$dirty && $v.form.location_id.$error"
                                      class="input-error"
                                    >
                                      {{ getLocationErrorMessage }}
                                    </div>
                                  </b-form-group>
                                  <div class="clearfix">&nbsp;</div>
                                    <b-form-group
                                    id="reason-group-1"
                                    label=""
                                    :class="{ 'required-input': true }"
                                    label-for="reason"
                                  >
                                    <b-form-textarea
                                      id="reason"
                                      v-model.trim="$v.form.reason.$model"
                                      :class="{ 'has-error': $v.form.reason.$error }"
                                      :disabled="isSubmitting"
                                      :placeholder="systemLanguages.VISITOR_REASON_TEXT"
                                      rows="3"
                                      max-rows="5"
                                    />
                                    <div
                                      v-if="$v.form.reason.$dirty && $v.form.reason.$error"
                                      class="input-error"
                                    >
                                      {{ getReasonErrorMessage }}
                                    </div>
                                  </b-form-group>
                                </div>
                              </div>
                            </div>
                            <div class="card-footer text-right">
                              <button class="btn btn-success" type="submit">{{ systemLanguages.NEXT_TEXT }}</button>
                          </div>
                          </b-form>
                          <div class="card-body text-left" v-if="step && step > 0">
                            <div class="col col-12 question">
                              <div class="col col-12 p-0 mb-3">
                                {{ systemLanguages.QUESTIONS_TEXT[step - 1] }}
                              </div>
                                <b-form-group class="mt-5 p-0 text-center">
                                  <b-form-radio-group v-model="answer">
                                                          <b-form-radio
                                                              class="question-check-group"
                                                              inline
                                                              name="rd_question"
                                                              @change="selectAnswer(1)"
                                                              value="1"
                                                          >{{ systemLanguages.YES_TEXT }}</b-form-radio>
                                                          <b-form-radio
                                                              inline
                                                              class="question-check-group ml-3"
                                                              name="rd_question"
                                                              value="0"
                                                              @change="selectAnswer(0)"
                                                          >{{ systemLanguages.NO_TEXT }}</b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>            
                            </div>
                          </div>
                        </template>
                      </template>
          </template>
      </div>
	  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import axios from 'axios';

import LOCATION_LIST from '../config/locations';
import { QUESTIONS_LIST, LANGUAGE_OPTIONS } from '../config/constants';

import LANGUAGE_EN from '../config/languages/en';
import LANGUAGE_ES from '../config/languages/es';


export default {
  name: 'Wizard',
  props: {},
  data() {
    // languages
    let selectedLanguage = 'en';
    let systemLanguages;

    if (selectedLanguage === 'en') {
      systemLanguages = LANGUAGE_EN;
    }

    const languageOptions = [{ value: '', text: ' - select a language - '}];

    Object.keys(LANGUAGE_OPTIONS).map((key) => {
      languageOptions.push({
        value: key,
        text: LANGUAGE_OPTIONS[`${key}`],
      });
    })

    const responses =  QUESTIONS_LIST.map((itm) => {
          return { question: itm, answer: null };
    });

    let appName = process.env.VUE_APP_APP_NAME || 'Covid-19 Questionnaire';

    return {
      step: 0,
      isSubmitting: false,
      appName,
      questionsList: QUESTIONS_LIST || [],
      form: {
        name: '',
        phone_number: '',
        location_id: '',
        reason: '',
      },
      answer: null,
      // locationOptions,
      canProceed: null,
      hasError: false,
      hasSelectedLanguage: false,
      formMessage: null,
      maxSteps: 4,
      responses,
      systemLanguages,
      languageOptions,
      selectedLanguage: '',
    }
  },
  validations() {
		return {
			form: {
				name: {
					required,
				},
				phone_number: {
					required,
					// numeric,
					// minLength: minLength(10),
				},
				location_id: {
					required,
				},
				reason: {
          required,
          minLength: minLength(5),
				},
		  }
		}
	},
  computed: {
    getNameErrorMessage: function() {
			return this.getErrorMessage('name');
		},
    getPhoneErrorMessage: function() {
			return this.getErrorMessage('phone_number');
		},
    getLocationErrorMessage: function() {
			return this.getErrorMessage('location_id');
		},
    getReasonErrorMessage: function() {
			return this.getErrorMessage('reason');
		},
  },
  methods: {
    setupApp() {
      let { appName, selectedLanguage, systemLanguages } = this;

      if (appName && this.selectedLanguage !== 'en') {
        if (selectedLanguage === 'es') systemLanguages = LANGUAGE_ES;

        appName = appName.replace('Questionnaire', systemLanguages.QUESTIONNAIRE_TEXT);

        this.systemLanguages = systemLanguages;
        this.appName = appName;
      }

      const locationOptions = [
        { value: '', text: `- ${systemLanguages.VISITOR_SELECT_OFFICE_LOCATION_TEXT} -` },
        ...LOCATION_LIST.map((loc) => {
          if (loc) Object.assign(loc, { value: loc.id, text: loc.name });

          return loc;
        }),
      ];

      this.locationOptions = locationOptions;

      if (selectedLanguage) {
        this.selectedLanguage = selectedLanguage;
        this.hasSelectedLanguage = true;
      }
    },
		getErrorMessage(field) {
      if (this.systemLanguages) return this.systemLanguages.REQUIRED_TEXT;

			return 'Required!';
    },
    selectLanguage(language) {
      this.selectedLanguage = language;

      this.setupApp();
    },
    async selectAnswer(answer) {
      if (this.responses && this.responses.length) {
        this.responses[this.step - 1].answer = answer;
      }

      if (answer === 0){
        if (this.step < 4) {
          this.answer = null,
          this.step++;

          return;
        } else {
            await this.notifyLocation(answer);   
            
            this.canProceed = true;
        }
      } else {
          await this.notifyLocation(answer);          

          this.canProceed = false;
      }
    },
    async notifyLocation(answer) {
      const loader = this.$loading.show();
      const data = this.form;
        
      Object.assign(data, { responses: this.responses });

      return axios.post(process.env.VUE_APP_SERVER_URL, data).then((result) => {
          this.hasError = false;
        }).catch((err) => {
          this.hasError = true;
          
          const errMsg = (err && err.data && err.data.message) ? err.data.message : this.systemLanguages.GENERIC_REQUEST_ERROR_TEXT;
          
          this.formMessage = errMsg;
        }).finally((err) => {
          this.isSubmitting = false;

          loader.hide();
        });
    },
    acceptNumber(value) {
      const x = this.form.phone_number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  
      this.form.phone_number = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    async saveInfo(e) {
      e.preventDefault();

      this.$v.$touch();
  
      if (this.$v.$invalid) return false;
      
      this.step++;

    },
    retrySubmission() {
      const loader = this.$loading.show();
      const caller = this;

      setTimeout(() => {
        caller.selectAnswer(this.canProceed ? 0 : 1);
        
        loader.hide();
      }, 2000);
    }
  }
}
</script>

<style scoped>
h4 {
  font-weight: bold;
}

.has-error {
    border: 1px solid #d33c40;
}

.page-error {
  font-size: 22px !important;
}

.input-error {
	color: #d33c40;
  font-size: 18px;
}

.bold {
  font-weight: bold;
}

.question {
  font-weight: bold;
  font-size: 20px;
  min-height: 400px;
  font-size: 20px;
}

.card-body {
  min-height: 400px;
}

.question-check-group {
  font-size: 1.3em;
}

.card-body.card-language {
    min-height: 200px;
}

/* .custom-select {
  font-size: 1.25rem !important;
  line-height: 1.5 !important;
}

textarea {
  font-size: 1.25rem !important;
} */
</style>
