const LANGUAGE_TEXTS = {
	QUESTIONNAIRE_TEXT: 'Questionnaire',
	RETRY_TEXT: 'Retry',
	ENTRY_SUCCESS_TEXT: "Thank you for completing the questionnaire, we'll let you in shortly.",
	ENTRY_FAILURE_TEXT: "Thank you for completing the questionnaire, we regret to inform you that we cannot serve you in person at this time, due to Covid-19. If you would still like to be served, please call the office at 800-799-5007.",
	VISITOR_INFO_TEXT: 'Welcome, please tell us a little about you.',
	VISITOR_NAME_TEXT: 'What is your name?',
	VISITOR_PHONE_NUMBER_TEXT: 'Enter your phone number',
	VISITOR_SELECT_OFFICE_LOCATION_TEXT: 'select a location',
	OFFICE_LOCATION_TEXT: 'Office location',
	REQUIRED_TEXT: 'Required',
	VISITOR_REASON_TEXT: 'Reason for visit',
	NEXT_TEXT: 'Next',
	GENERIC_REQUEST_ERROR_TEXT: 'We are unable to process your request at this time, please try again later',
	YES_TEXT: 'Yes',
	NO_TEXT: 'No',
	QUESTIONS_TEXT: [
		'Have you or a member of your household been experiencing any symptoms of COVID-19, including fever, cough, or shortness of breath?',
		'Have you or a member of your household been out of the country within the last 14-18 days?',
		'Have you or a member of your household been in close contact with anyone who has been tested,diagnosed, or told by a medical professional they may have COVID-19?',
		'Have you or a member of your household been in close contact with anyone who has travelled overseas or to a high-risk area within the last 14-18 days?',
	],
	INPUT_ERROR_TEXT: 'We are unable to process you request, please check your input and try again!',
};

export default LANGUAGE_TEXTS;
