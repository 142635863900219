<template>
  <div id="app">
    <div class="container">
      <div class="col col-12 text-center pt-4 pb-4">
        <img alt="Caresify logo" src="./assets/img/logo.png" class="img-fluid" width="180"/>
      </div>
      <Wizard/>
      <div class="col col-12 text-center copyright-footer pt-5">
        Copyright &copy; 2020 Caresify LLC. All rights reserved.
      </div>
    </div>
  </div>
</template>

<script>
import Wizard from './components/Form.vue';

export default {
  name: 'App',
  components: {
    Wizard
  }
}
</script>

<style>
@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"),
   url(./assets/fonts/Quicksand-Regular.otf);
}

@font-face {
  font-family: "Quicksand-Medium";
  src: local("Quicksand-Medium"),
   url(./assets/fonts/Quicksand-Medium.ttf);
}

@font-face {
  font-family: "Quicksand-Bold";
  src: local("Quicksand-Bold"),
   url(./assets/fonts/Quicksand-Bold.otf);
}

#app {
  font-family: "Quicksand", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  font-size: 1.2em;
  margin-top: 0px;
}

.copyright-footer {
  font-size: 16px;
}
</style>
