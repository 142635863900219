import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import Loading from 'vue-loading-overlay';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-loading-overlay/dist/vue-loading.css';

import App from './App.vue'

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(Loading);


new Vue({
  render: h => h(App),
}).$mount('#app')
